<template>
  <div>
    <v-container
      class="v-container-fluid"
      fluid
    >
      <v-row>
        <v-col cols="12">
          <div>
            <div class="display-flex align-items-center">
              <p class="txt-title mb-0 mr-1 mon-bold">{{ texts.catalogType.titleSection }}</p>
              <img
                width="25px"
                height="25px"
                src="@/assets/images/canasta.png"
                alt=""
              >
            </div>
            <v-container
              class="px-0"
              fluid
            >
              <!-- skeleton loader -->
              <home-catalog-type-skeleton-layout v-if="loaderSkeleton" />
              <v-row v-else>
                <v-col>
                  <div class="scrolled-lateral">
                    <div
                      class="container-card-gradient"
                      v-for="(item, index) in catalogsTypes"
                      :key="index"
                    >
                      <div>
                        <p class="txt-title-card mon-bold">
                          {{ item.sName }}
                          <img
                            width="16px"
                            :src="item.oImageKey.xs"
                            alt=""
                          >
                        </p>
                        <div class="display-flex justify-content-center mb-1">
                          <v-btn
                            :to="{
                              name: 'Catalog', params: {
                                id: `${item.sCategoryTypeId}`
                              }
                            }"
                            class="button-card mon-bold"
                            elevation="0"
                          >
                            {{ texts.catalogType.buttonViewCatalogs}}
                            <v-icon
                              size="13px"
                              color="#ffffff"
                            >
                              mdi-arrow-right
                            </v-icon>
                          </v-btn>
                        </div>
                      </div>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
export default {
  props: {
    texts: {
      type: Object,
      default: "",
    },
  },
  name: "HomeCatalogoTypeLayout",
  data() {
    return {
      //VARIABLES
      catalogsTypes: [],
      loaderSkeleton: true,
    };
  },
  beforeMount() {
    this.getCatalogsTypes();
  },
  methods: {
    getCatalogsTypes: function () {
      this.loaderSkeleton = true;
      DB.get(`${URI}/api/v1/${this.selectLanguage}/types`, {
        headers: {},
        params: {},
      })
        .then((response) => {
          this.loaderSkeleton = false;

          this.catalogsTypes = response.data.results;
        })
        .catch((error) => {
          this.loaderSkeleton = false;

          this.mixError(error.response.data.message, error.response.status);
        });
    },
  },
  computed: {
    selectLanguage: function () {
      return this.$store.state.language;
    },
  },
  watch: {
    selectLanguage: function () {
      if (this.selectLanguage) {
        this.getCatalogsTypes();
      }
    },
  },
};
</script>

<style scoped>
.container-card-gradient {
  min-width: 250px;
  background: transparent linear-gradient(243deg, #ff8181 0%, #ffa77e 100%) 0%
    0% no-repeat padding-box;
  border-radius: 11px;
  opacity: 1;
  padding: 10px 0px;
  margin-right: 15px;
}

.scrolled-lateral {
  width: 100%;
  display: flex;
  overflow-x: auto;
}

.txt-title {
  text-align: left;
  letter-spacing: 0px;
  color: #3d414a;
  opacity: 1;
  font-size: 18px;
  font-weight: 600;
}

.txt-title-card {
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  margin-bottom: 10px;
  font-size: 18px;
}

.button-card {
  width: 80%;
  border: 1px solid #ffffff;
  border-radius: 13px;
  opacity: 1;
  text-align: center;
  letter-spacing: 0px;
  color: #ffffff !important;
  opacity: 1;
  font-size: 10px !important;
  background-color: transparent !important;
  text-transform: initial;
}

@media (max-width: 960px) {
  .hidden-from-tablet {
    display: none;
  }
}
</style>